<template>
  <div id="user-list">
    <v-snackbar v-model="isSnackbarVisible" :color="snackbar.color" :timeout="snackbar.timeout" top right auto-height>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon == 'success' ? icons.mdiCheckBold : icons.mdiCheckBold }}
        </v-icon>

        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <v-dialog v-if="isDialogVisible" v-model="isDialogVisible" max-width="100%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Lista de ordenes</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <ordersList :dataOrders="dataOrders" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="isDialogVisible = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- list filters -->
    <h2 class="m-2">REPORTES</h2>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <label for="">Desde &nbsp</label>
        <input v-model="desde" type="date" id="desde" name="desde" />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <label for="">Hasta &nbsp</label>
        <input v-model="hasta" type="date" id="hasta" name="hasta" />
      </v-col>
    </v-row>

    <v-card style="margin-top: 10px">
      <v-btn color="info" class="m-4" @click="generarReporte('ventasxcliente')">
        <!-- <v-icon center>{{ icons.mdiPlusCircle }} </v-icon> -->
        &nbsp;Ventas x Cliente
      </v-btn>
      <v-btn color="info" class="m-4" @click="generarReporte('ventasxdriver')">
        <!-- <v-icon center>{{ icons.mdiPlusCircle }} </v-icon> -->
        &nbsp;Ventas x Driver
      </v-btn>
      <v-btn color="info" class="m-4" @click="generarReporte('ventasxtipo1')">
        <!-- <v-icon center>{{ icons.mdiPlusCircle }} </v-icon> -->
        &nbsp;Ventas x orden A-B
      </v-btn>
      <v-btn color="info" class="m-4" @click="generarReporte('ventasxtipo2')">
        <!-- <v-icon center>{{ icons.mdiPlusCircle }} </v-icon> -->
        &nbsp;Ventas x orden Multipuntos
      </v-btn>

      <download-excel class="vv-btn" :data="items" :fields="json_fields" worksheet="My Worksheet" name="Reportes.xls">
        Descargar datos
      </download-excel>
      <!-- table -->
      <!-- v-model="selectedRows" -->
      <!-- :options.sync="options" -->
      <v-data-table v-if="tipo == 'ventasxcliente'" :loading="loading" :headers="tableColumns" :items="items"
        :server-items-length="totalItems">
        <template #[`item.total`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">C$ {{ item.total }}</span>
        </template>
      </v-data-table>

      <v-data-table v-if="tipo == 'ventasxdriver'" :loading="loading" :headers="tableColumns1" :items="items"
        :server-items-length="totalItems">
        <template #[`item.total`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">C$ {{ item.total }}</span>
        </template>
        <!-- actions -->
        <template #[`item.acciones`]="{ item }">
          <!-- <input class="btn-primary" type="button" ></input> -->
          <button class="btn btn-green" @click="showModal(item)">
            <v-icon center style="color: white"> {{ icons.mdiPencil }} </v-icon>
          </button>
        </template>
      </v-data-table>

      <v-data-table v-if="tipo == 'ventasxtipo1'" :loading="loading" :headers="tableColumns" :items="items"
        :server-items-length="totalItems">
        <template #[`item.total`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">C$ {{ item.total }}</span>
        </template>
      </v-data-table>

      <v-data-table v-if="tipo == 'ventasxtipo2'" :loading="loading" :headers="tableColumns" :items="items"
        :server-items-length="totalItems">
        <template #[`item.total`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">C$ {{ item.total }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiCheckBold, mdiDeleteOutline, mdiPencil, mdiPlusCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import ordersList from '../ya_orders/OrdersListByDriver.vue'

import Vue from 'vue'

import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)

// sidebar

export default {
  components: { ordersList },
  data() {
    return {
      icons: { mdiDeleteOutline, mdiPencil, mdiPlusCircle, mdiCheckBold },
      items: [],
      table: 'reportes',
      tableColumns: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'Nombre', value: 'nombre', sortable: true },
        { text: 'Cantidad', value: 'cantidad', sortable: true },
        { text: 'Total', value: 'total', sortable: true },
        // { text: 'Estado', value: 'estado', sortable: true },
        // { text: 'Acciones', value: 'acciones', sortable: false },

        // {id:0, name:}
      ],
      tableColumns1: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'Nombre', value: 'nombre', sortable: true },
        { text: 'Cantidad', value: 'cantidad', sortable: true },
        { text: 'Total', value: 'total', sortable: true },
        { text: 'Acciones', value: 'acciones', sortable: false, width: '130px' },

        // { text: 'Estado', value: 'estado', sortable: true },
        // { text: 'Acciones', value: 'acciones', sortable: false },

        // {id:0, name:}
      ],
      loading: false,
      totalItems: 0,
      isDialogVisible: false,
      row: {},
      modalTitle: 'Nuevo cliente',
      commerceList: [],
      formHasErrors: false,
      errorMessages: '',
      form: ref(null),
      valid: ref(true),
      isSnackbarVisible: false,
      message: '',
      messageColor: 'primary',
      icon: '',
      snackbar: {
        color: 'success',
        icon: 'check_circle',
        mode: 'multi-line',
        title: 'Success',
        text: 'That worked, hoorah.',
        visible: true,
      },
      statusList: [{ nombre: 'Activo' }, { nombre: 'Inactivo' }],
      file: null,
      filename1: null,
      desde: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      hasta: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      tipo: '',
      json_fields: {
        nombre: 'nombre',
        email: 'email',
        celular: 'celular',
      },
      dataOrders: {},
    }
  },
  async mounted() {
    // await this.getData()
    // await this.getCommerce()
  },
  methods: {
    showModal(id) {
      this.dataOrders = {}
      id = id.conductor_id
      this.dataOrders = {
        driverId: id,
        desde: this.desde,
        hasta: this.hasta,
      }

      this.isDialogVisible = true
    },
    async generarReporte(tipo) {
      try {
        this.tipo = tipo
        this.loading = true
        let res = await this.$store.dispatch('get', { path: `reportes/${tipo}/${this.desde}/${this.hasta}` })
        this.loading = false
        if (res.length > 0) {
          this.items = res
          this.totalItems = this.items.length
        } else {
          this.items = []
          this.totalItems = this.items.length
        }
      } catch (error) {
        this.items = []
        this.loading = false
        console.error('error', error)
      }
    },
    onFileSelected(type, item) {
      this.file1 = event.target.files[0]
      this.filename1 = Math.floor(Math.random() * 9999999999999 + 1)
      this.filename1 = type + '_' + this.filename1 + '.jpg'
    },
    async saveData() {
      try {
        //pendiente la validación del form
        // this.formHasErrors = false
        //let row = Object.assign({}, this.row)
        let row = { ...this.row }

        let uploadImg
        //this.$isLoading(true)
        let url_img = ''
        if (this.filename1 != null) {
          uploadImg = await this.$store.dispatch('onFileSelected', { filename: this.filename1, file: this.file1 })
          if (uploadImg == true) {
            console.log('----', uploadImg)
            row.url_img = 'https://appyaqui.com/recursosapps/img/' + this.filename1
          }
        }

        let res
        if (row.id) {
          //es update
          delete row.id
          res = await this.$store.dispatch('put', { path: this.table + '/modify/' + this.row.id, data: row })
        } else {
          res = await this.$store.dispatch('post', { path: this.table + '/new/', data: row })
          //es post
        }
        this.getData()
        this.snackbar = {
          color: 'success',
          icon: 'success',
          mode: 'multi-line',
          title: 'Muy bien!',
          text: 'Registro procesado correctamente!.',
          timeout: '2500',
        }

        this.isDialogVisible = false
        this.isSnackbarVisible = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },

    async getData() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: this.table + '/getAll/' })
        this.loading = false
        if (res.length > 0) {
          this.items = res
          this.totalItems = this.items.length
        }
      } catch (error) {
        this.items = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getCommerce() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'comercios/getAll/' })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.commerceList = res
        }
      } catch (error) {
        this.commerceList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async deleteData(item) {
      try {
        let txt
        if (confirm('¿Eliminar Registro?')) {
          this.loading = true
          let res = await this.$store.dispatch('delete', { path: this.table + '/' + item.id })
          await this.getData()
          this.loading = false
          txt = 'You pressed OK!'
          this.snackbar = {
            color: 'success',
            icon: 'success',
            mode: 'multi-line',
            title: 'Muy bien!',
            text: 'Registro elimiado correctamente!.',
            timeout: '2500',
          }
          this.isSnackbarVisible = true
        } else {
          //txt = 'You pressed Cancel!'
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
}

.btn-green {
  color: rgb(255, 255, 255) !important;
  background-color: #20b11b !important;
  border-color: #20b11b !important;
}

.btn-blue {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #4e97e6 !important;
}

.btn-red {
  color: #fff !important;
  background-color: #ff0037 !important;
  border-color: #ff0037 !important;
}

.vv-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  margin: 10px !important;
  cursor: pointer;
  background-color: rgb(48, 146, 48);
  color: white;
}

.v-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  margin: 5px !important;
  cursor: pointer;
}
</style>

<template>
  <div id="categories-list" class="wrapper">
    <v-snackbar v-model="isSnackbarVisible" :color="snackbar.color" :timeout="snackbar.timeout" top right auto-height>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon == 'success' ? icons.mdiCheckBold : icons.mdiCheckBold }}
        </v-icon>

        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <!-- modal correcto -->
    <v-dialog v-model="isDialogVisible" max-width="800px">
      <v-card>
        <v-form @submit.prevent="saveData">
          <v-card-title>
            <span class="headline">{{ modalTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <label for="">Fecha orden</label><br />
                  <!-- <input required class="form-control" v-model="row.fecha_orden" type="datetime-local" name="datetime"> -->

                  <v-text-field label="" dense v-model="row.fecha_orden"
                    :rules="[() => !!row.fecha_orden || 'Este campo es requerido.']" :error-messages="errorMessages"
                    ref="fecha" required type="datetime-local" readonly></v-text-field>
                </v-col>

                <v-col cols="6">
                  <label for="">Fecha entregada</label><br />
                  <input class="form-control" readonly
                    :value="$moment(row.fecha_finalizado).utc().format('DD-MM-YYYY hh:mm')" type="datetime-local"
                    name="datetime" />
                </v-col>

                <v-col cols="6">
                  <v-select required readonly v-model="row.cliente_id" :items="customersList" label="Cliente" dense
                    item-text="nombre" item-value="id"></v-select>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-select v-model="row.tipo" readonly :items="tipoPedido" label="Tipo de pedido" dense
                    item-text="nombre" item-value="id"></v-select>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <label for="">Total C$</label><br />
                  <input class="form-control" readonly v-model="row.total" type="text" name="datetime" />
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <label for="">Total a recaudar C$</label><br />
                  <input class="form-control" readonly v-model="row.total_arecaudar" type="text" name="" />
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <label for="">Total Facturas C$</label><br />
                  <input class="form-control" readonly v-model="row.total_facturas" type="text" name="" />
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <label for="">Total Neto C$</label><br />
                  <input class="form-control" readonly v-model="row.total_neto" type="text" name="" />
                </v-col>
                <v-col cols="12" v-if="distanciaViaje > 0">
                  {{ calculos }}
                </v-col>

                <v-col cols="6">
                  <v-text-field label="Estado" dense type="text" min="1" readonly :value="row.estado == 0
                    ? 'En espera'
                    : row.estado > 0 && row.estado < 99
                      ? 'En proceso'
                      : row.estado == 99
                        ? 'Finalizada'
                        : '--'
                    "></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-select v-model="row.metodo_pago" :items="metodoPago" label="Método de pago" dense
                    item-text="nombre" item-value="nombre" readonly></v-select>
                </v-col>
                <!-- nuevas opciones -->
                <v-col cols="6">
                  <v-select v-model="row.estado_pago_envio" :items="listaEstadoEnvio" label="Estado pago envío" dense
                    item-text="nombre" item-value="nombre"></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select v-model="row.estado_dinero_recogido" :items="listaEstadoDineroRecogido"
                    label="Estado dinero recogido" dense item-text="nombre" item-value="nombre"></v-select>
                </v-col>
                <!-- fin nuevas opciones -->
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="isDialogVisible = false"> Cerrar </v-btn>
            <v-btn type="submit" color="success">
              <!-- @click="isDialogVisible = false" -->
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- modal 2 MULTIPUNTOS no -->
    <v-dialog v-model="isDialogVisible2" max-width="800px">
      <v-card>
        <v-form @submit.prevent="saveData2">
          <v-card-title>
            <span class="headline">{{ modalTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <label for="">Fecha orden</label><br />

                  <v-text-field label="" dense v-model="row.fecha_orden"
                    :rules="[() => !!row.fecha_orden || 'Este campo es requerido.']" :error-messages="errorMessages"
                    ref="fecha" required type="datetime-local"></v-text-field>
                </v-col>

                <v-col cols="6">
                  <!-- <label for="">Fecha entregada</label><br />
                  <input
                    class="form-control"
                    readonly
                    v-model="row.fecha_finalizado"
                    type="datetime-local"
                    name="datetime"
                  /> -->
                </v-col>

                <v-col cols="6">
                  <v-select required v-model="row.cliente_id" :items="customersList" label="Cliente" dense
                    item-text="nombre" item-value="id"></v-select>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-select v-model="row.tipo" :items="tipoPedido" label="Tipo de pedido" dense item-text="nombre"
                    item-value="id"></v-select>
                </v-col>

                <!-- <v-col cols="12" sm="12" md="12">
                <v-text-field 
                  label="Descripción"
                  dense
                  v-model="row.descripcion"
                  :rules="[() => !!row.descripcion || 'Este campo es requerido.']"
                  :error-messages="errorMessages"
                  ref="descripcion"
                  required
                ></v-text-field>
              </v-col> -->

                <!-- mapa -->
                <v-col v-if="showMapa == 1" cols="12" sm="12" md="12">
                  <div id="map" style="height: 300px">
                    <MglMap :center.sync="center" :accessToken="accessToken" :mapStyle.sync="mapStyle" :zoom="zoom"
                      @click="clickMapaMultipuntos">
                      <MglMarker v-for="marker in coordinates" :coordinates="marker.coordinates" :key="marker.id">
                        <MglPopup>
                          <div>
                            <span style="font-size: 25px important!">{{
                              $moment(marker.comentario).utc().format('DD-MM-YYYY hh:mm:ss')
                            }}</span>
                          </div>
                        </MglPopup>
                      </MglMarker>
                    </MglMap>
                  </div>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field label="Coordenadas" dense v-model="row.coordenadas_inicio" ref="cci"
                    type="text"></v-text-field>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <input class="form-control" type="text" v-model="address" placeholder="Dirección a buscar" id="" />
                  <!-- <v-text-field
                  v-model="address"
                  label="Buscar dirección"
                  placeholder="dirección"
                  class="mb-6"
                ></v-text-field> -->
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-btn color="secondary" @click="buscarDirecciones()"> Buscar Direcciones </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-select @change="seleccionarDireccion($event)" required v-model="cliente" :items="addressList"
                    label="Direcciones encontradas:" dense item-text="name" item-value="center"></v-select>
                </v-col>

                <!-- <v-col cols="6" sm="6" md="6">
              <label>{{row.tipo}}</label>
              </v-col> -->
                <v-col cols="6" sm="12" md="6">
                  <v-text-field label="Dirección exacta" dense v-model="rowAddress.direccionExacta"
                    :rules="[() => !!rowAddress.descripcion || 'Este campo es requerido.']"
                    :error-messages="errorMessages" ref="descripcion" required></v-text-field>
                </v-col>

                <v-col cols="6" sm="12" md="6">
                  <v-text-field label="Teléfono" dense v-model="rowAddress.telefono"
                    :rules="[() => !!rowAddress.telefono || 'Este campo es requerido.']" :error-messages="errorMessages"
                    ref="descripcion" required></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field label="Detalle" dense v-model="rowAddress.detalle"
                    :rules="[() => !!rowAddress.detalle || 'Este campo es requerido.']" :error-messages="errorMessages"
                    ref="descripcion" required></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn color="secondary" @click="agregarPunto()"> Agregar punto </v-btn>
                </v-col>

                <!-- inicio direcciones -->
                <v-col cols="12" sm="12" md="12">
                  <v-row>
                    <v-flex xs12 sm12 md12 lg12 v-for="index of coordenadasMultipunto" :key="index.id">
                      <v-card>
                        <v-card-text>{{ index.nombre + ' / ' + index.telefono + ' / ' + index.coordenadas }}

                          <v-btn style="padding: 1px !important; min-width: 1px" color="error"
                            @click="removePoint(index)">
                            <v-icon center>{{ icons.mdiDeleteOutline }} </v-icon>
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-row>
                  <!-- fin direcciones -->
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <v-btn color="secondary" @click="calcularPrecioMultipuntos()"> Calcular total </v-btn>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <v-text-field label="Total" dense v-model="row.total" ref="total" :key="row.total"
                    type="number"></v-text-field>
                </v-col>

                <v-col cols="12" v-if="distanciaViaje > 0">
                  {{ calculos }}
                </v-col>

                <v-col cols="6">
                  <v-text-field label="Estado" dense type="text" min="1" readonly :value="row.estado == 0
                    ? 'En espera'
                    : row.estado > 0 && row.estado < 99
                      ? 'En proceso'
                      : row.estado == 99
                        ? 'Finalizada'
                        : '--'
                    "></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-select v-model="row.metodo_pago" :items="metodoPago" label="Método de pago" dense
                    item-text="nombre" item-value="nombre"></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="isDialogVisible2 = false"> Cerrar </v-btn>
            <v-btn type="submit" color="success">
              <!-- @click="isDialogVisible = false" -->
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- modal 3 candidatos -->
    <v-dialog v-model="isDialogVisible3" max-width="800px">
      <v-card>
        <v-form @submit.prevent="assignOrder">
          <v-card-title>
            <span class="headline">Asignar orden</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select @change="selCandidate($event)" required v-model="candidate" :items="candidates"
                    label="Drivers:" dense item-text="nombre" item-value="id"></v-select>
                </v-col>

                <v-col cols="12">
                  <v-btn color="secondary" @click="assignOrder()"> Asignar</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="isDialogVisible3 = false"> Cerrar </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- titulo -->
    <h2 class="m-2">ORDENES</h2>
    <v-card>
      <template>
        <div class="custom-search">
          <input v-model="textoFiltrar" type="text" class="custom-search-input"
            placeholder="Número de orden a buscar" />
          <button @click="buscar()" class="custom-search-botton" type="submit">Buscar</button>
        </div>
      </template>

      <v-data-table :loading="loading" :headers="tableColumns" :items="items" :item-class="itemRowBackground"
        :server-items-length="totalItems">
        <!-- name -->
        <template #[`item.urlfoto`]="{ item }">
          <div class="d-flex align-center">
            <v-img class="img-fluid" v-if="item.urlfoto" :src="item.urlfoto" width="25px" height="40px"
              style="margin: 8px"></v-img>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar size="30" :color="resolveUserRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role)}--text me-3`">
              <v-icon size="18" :color="resolveUserRoleVariant(item.role)">
                {{ resolveUserRoleIcon(item.role) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.role }}</span>
          </div>
        </template>

        <!-- plan -->
        <template #[`item.fecha_orden`]="{ item }">
          <span :class="item.estado == null ? ' .bg_alert .bg_alert :hover' : ''">{{
            item.fecha_orden != null && item.fecha_orden != '0000-00-00 00:00:00'
              ? $moment(item.fecha_orden).utc().format('DD-MM-YYYY hh:mm:ss')
              : ''
          }}</span>
        </template>

        <template #[`item.fecha_finalizado`]="{ item }">
          <span>{{
            item.fecha_finalizado != null ? $moment(item.fecha_finalizado).utc().format('DD-MM-YYYY hh:mm:ss') : ''
          }}</span>
        </template>
        <template #[`item.estado`]="{ item }">
          <span>{{
            item.estado == '' || item.estado == null
              ? 'Asignando driver'
              : item.estado == -1
                ? 'Driver se dirige al punto A'
                : item.estado == 0
                  ? 'Driver se dirige al punto B'
                  : item.estado == 1
                    ? 'Driver se dirige al punto C'
                    : item.estado == 2
                      ? 'Driver se dirige al punto D'
                      : item.estado == 2
                        ? 'Driver se dirige al punto E'
                        : item.estado == 2
                          ? 'Driver se dirige al punto F'
                          : item.estado == 2
                            ? 'Driver se dirige al punto G'
                            : item.estado == 99
                              ? 'Orden finalizada'
                              : ''
          }}</span>
        </template>

        <template #[`item.total`]="{ item }">
          <span class="font-weight-semibold text--primary">C${{ item.total > 0 ? item.total.toFixed(2) : 0.0 }}</span>
        </template>
        <template #[`item.total_arecaudar`]="{ item }">
          <span class="font-weight-semibold text--primary">C${{ item.total_arecaudar > 0 ?
            item.total_arecaudar.toFixed(2) : 0.0 }}</span>
        </template>
        <template #[`item.total_facturas`]="{ item }">
          <span class="font-weight-semibold text--primary">C${{ item.total_facturas > 0 ? item.total_facturas.toFixed(2)
            : 0.0 }}</span>
        </template>
        <template #[`item.total_neto`]="{ item }">
          <span class="font-weight-semibold text--primary">C${{ item.total_neto != null ? item.total_neto.toFixed(2) :
            0.0 }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip small :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize">
            {{ item.status }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.acciones`]="{ item }">
          <!-- <input class="btn-primary" type="button" ></input> -->
          <button class="btn btn-green" @click="showModal(2, item)">
            <v-icon center style="color: white"> {{ icons.mdiPencil }} </v-icon>
          </button>
          &nbsp;
          <button class="btn btn-blue" @click="$router.push('/apps/maporder/' + item.id)">
            <v-icon center style="color: white"> {{ icons.mdiMapMarkerRadius }} </v-icon>
          </button>
          &nbsp;
          <button class="btn btn-blue2" @click="seguimientoOrden(item.id)">
            <v-icon center style="color: white"> {{ icons.mdiMapMarkerRadiusOutline }} </v-icon>
          </button>
          &nbsp;
          <!-- <button class="btn btn-red" @click="deleteData(item)">
            <v-icon center style="color: white"> {{ icons.mdiDeleteOutline }} </v-icon>
          </button> -->
          &nbsp;
          <button v-if="item.estado == null" class="btn btn-orange" @click="getCandidates(item)">
            <v-icon center style="color: white"> {{ icons.mdiAccountCheckOutline }} </v-icon>
          </button>
          &nbsp;
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAccountCheckOutline,
  mdiCheckBold,
  mdiDeleteOutline,
  mdiMapMarkerRadius,
  mdiMapMarkerRadiusOutline,
  mdiMapSearch,
  mdiPencil,
  mdiPlusCircle,
} from '@mdi/js';
import { ref } from '@vue/composition-api';
import { MglGeojsonLayer, MglGeolocateControl, MglMap, MglMarker, MglNavigationControl, MglPopup } from 'vue-mapbox';
var CryptoJS = require('crypto-js')

export default {
  props: ['dataOrders'],
  components: {
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglNavigationControl,
    MglGeolocateControl,
    MglPopup,
    mdiAccountCheckOutline,
  },
  data() {
    return {
      icons: {
        mdiMapMarkerRadiusOutline,
        mdiMapMarkerRadius,
        mdiDeleteOutline,
        mdiPencil,
        mdiPlusCircle,
        mdiCheckBold,
        mdiMapSearch,
        mdiAccountCheckOutline,
      },
      items: [],
      table: 'ordenes',
      tableColumns: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'Fecha Orden', value: 'fecha_orden', sortable: true },
        { text: 'Driver', value: 'nombreDriver', sortable: true },
        // { text: 'Fecha Finalizado', value: 'fecha_finalizado', sortable: true },
        { text: 'Costo servicio', value: 'total', sortable: true },
        { text: 'Total recaudado', value: 'total_arecaudar', sortable: true },
        { text: 'Total facturas', value: 'total_facturas', sortable: true },
        { text: 'Total neto', value: 'total_neto', sortable: true },
        { text: 'Estado pago envío', value: 'estado_pago_envio', sortable: true },
        { text: 'Estado dinero recogido', value: 'estado_dinero_recogido', sortable: true },

        { text: 'Estado', value: 'estado', sortable: true },
        { text: 'Acciones', value: 'acciones', sortable: false, width: '130px' },

        // {id:0, name:}
      ],
      loading: false,
      totalItems: 0,
      isDialogVisible: false,
      row: {
        coordenadas_inicio: '',
        coordenadas_entrega: '',
        total: 0,
        tipo: 0,
      },
      modalTitle: 'Nueva Orden',
      customersList: [],
      categoriesList: [],
      subcategoriesList: [],
      commerceList: [],
      formHasErrors: false,
      errorMessages: '',
      form: ref(null),
      valid: ref(true),
      isSnackbarVisible: false,
      message: '',
      messageColor: 'primary',
      icon: '',
      snackbar: {
        color: 'success',
        icon: 'check_circle',
        mode: 'multi-line',
        title: 'Success',
        text: 'That worked, hoorah.',
        visible: true,
      },
      statusList: [{ nombre: 'En espera' }, { nombre: 'Inactivo' }],
      tipoPedido: [
        { id: 1, nombre: 'Enviar a traer desde mi ubicación' },
        { id: 2, nombre: 'Multi Puntos' },
      ],
      calculos: '',
      distanciaViaje: 0,
      metodoPago: [{ nombre: 'Tarjeta de C.' }, { nombre: 'Efectivo' }],

      accessToken: 'pk.eyJ1IjoicGVkaWRvc3lhIiwiYSI6ImNsMnNkNGl4ODBqaDgzZW8zN3VvY2c1YWMifQ.sOFdwHG62peplh1SCnpxgQ',
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      stateList: [{ name: 'Activo' }, { name: 'Pendiente' }],

      geojson: {
        type: 'FeatureCollection',
        features: [
          {
            properties: { name: 'south park' },
            geometry: {
              coordinates: [-95.2358865737915, 38.96194491354418],
            },
          },
          {
            properties: { name: 'Buford M Watson, Jr. Park' },
            geometry: {
              coordinates: [-95.23893356323242, 38.97148792405487],
            },
          },
        ],
      },
      layerId: 'firstLayer',
      sourceId: 'firstSource',
      coordinates: [],
      center: [-86.108508886867, 11.912447380368315],
      zoom: 8,
      showMapa: 1,
      coordenadas: '',
      tipocoord: 0,
      isDialogVisible2: false,
      coordenadasMultipunto: [],
      calculosMultipuntos: [],
      address: '',
      addressList: [],
      elMapa: null,
      rowAddress: {},
      cliente: '',
      urlSeguimiento: '',
      textoFiltrar: '',
      items2: [],
      candidates: [],
      isDialogVisible3: false,
      candidate: 0,
      order: 0,
      listaEstadoEnvio: [
        { nombre: 'Pagado' },
        { nombre: 'Pendiente de pago' },
        { nombre: 'Cancelado' },
        { nombre: 'Pendiente de cobrar' },
      ],
      listaEstadoDineroRecogido: ['Pendiente por depositar', 'Depositado a cuenta', 'Entrega en efectivo', 'Otros'],
    }
  },
  async mounted() {
    console.log(this.dataOrders)
    await this.getData()
    await this.getCategories()
    await this.getCommerce()
    await this.getCustomers()
  },
  methods: {
    itemRowBackground(item) {
      // console.log('it ', item.estado_pago_envio)
      return item.estado_pago_envio == 'Pagado'
        ? 'bg-white'
        : item.estado_pago_envio == 'Pendiente de pago'
          ? 'bg-yellow'
          : item.estado_pago_envio == 'Pendiente de cobrar'
            ? 'bg-lightblue'
            : item.estado_pago_envio == 'Cancelado'
              ? 'bg-red'
              : 'bg-white'
    },
    selCandidate(e) {
      //console.log('candidate0->', this.candidate)
    },
    async getCandidates(orderData) {
      console.log('getcandidate', orderData)
      try {
        let res = await this.$store.dispatch('get', { path: 'ordenes/getCandidates' })
        if (res.length > 0) {
          this.candidates = res
        } else {
          this.candidates = []
        }
        console.log(' candidatos ', this.candidates)
        this.order = orderData.id
        this.isDialogVisible3 = true
      } catch (error) {
        console.log('error getcandidates', error)
        this.candidates = []
      }
    },
    async assignOrder() {
      try {
        console.log('candidato', this.candidate)
        let path = `ordenes/asignarOrden/${this.order}/${this.candidate}`
        console.log('path', path)
        let res = await this.$store.dispatch('post', { path: path })
        console.log('res', res)
        this.candidate = 0
        this.candidates = []
        this.order = 0
        this.isDialogVisible3 = false
        setTimeout(async () => {
          await this.getData()
        }, 3000)
      } catch (error) {
        console.log('error assingorder ', error)
      }
    },
    row_classes(item) {
      return item.estado == null ? ' bg_alert ' : ''
    },
    buscar() {
      if (this.textoFiltrar == '') {
        this.items = [...this.items2]
        this.totalItems = this.items2.length
      } else {
        // this.tabla1 = this.tabla1.filter((x) => (x.nombre != null && x.apellidos != null && x.email != null && x.dni != null ) &&
        // (x.nombre.toUpperCase().includes(this.textoFiltrar.toUpperCase()) || x.email.toUpperCase().includes(this.textoFiltrar.toUpperCase())
        // || x.apellidos.toUpperCase().includes(this.textoFiltrar.toUpperCase()) || x.dni.toUpperCase().includes(this.textoFiltrar.toUpperCase()) ))
        // console.log(this.textoFiltrar)

        this.items = this.items2.filter(
          x => x.id == this.textoFiltrar || x.nombreDriver.toUpperCase().includes(this.textoFiltrar.toUpperCase()),
        )

        console.log(this.items)
        this.totalItems = this.items.length
      }
    },
    async seguimientoOrden(id) {
      try {
        console.log(' id ', id)
        id = id.toString()
        let encData = await CryptoJS.AES.encrypt(id, 'YaquiApp123456').toString()
        console.log('encdata', encData)

        // let cadena = 'https://admin.appyaqui.com/apps/maporder/' + encData
        // let cadena = 'http://localhost:8081/trackingorder/' + encData
        let cadena = 'https://tracking.appyaqui.com/#/' + encodeURIComponent(encData)
        setTimeout(() => {
          window.open(cadena)
        }, 1000)
        // return cadena
      } catch (error) {
        console.log('error', error)
      }
    },
    async centerAndAnimate(lat, lng) {
      try {
        console.log('actualizando, centrando y animando camara')
        this.center = lat + ', ' + lng
        //this.elMapa.map.setCenter({
        // this.elMapa.map.setCenter({
        //   lat: lat, //52.3602160, // mandatory
        //   lng: lng, //4.8891680, // mandatory
        //   animated: true, // default true,
        //   zoomLevel: 20,
        // })
        // this.elMapa.map.animateCamera({
        //   // this is where we animate to
        //   target: {
        //     lat: lat,
        //     lng: lng,
        //   },
        //   zoomLevel: 15.5, // Android
        //   //altitude: 2000, // iOS (meters from the ground)
        //   //bearing: 270, // Where the camera is pointing, 0-360 (degrees)
        //   tilt: 50,
        //   duration: 2000, // default 10000 (milliseconds)
        // })
        return true
      } catch (error) {
        console.log('error', error)
        return false
      }
    },
    async onMapReady(args) {
      console.log('mapa leido')
      this.elMapa = args
      //await centerAndAnimate(args, this.lat, this.lng)
    },
    async seleccionarDireccion(item) {
      let lista = [...this.addressList]
      let pos = lista.find(x => x.center == item)
      this.rowAddress.direccionExacta = pos.name
      console.log('posición', pos)
      console.log(item)
      this.row.coordenadas_inicio = item[1] + ',' + item[0]
      this.coordinates = []
      this.coordenadas = item[0] + ',' + item[1]
      let data = { coordinates: JSON.parse('[' + this.coordenadas + ']'), cliente_id: 1 }
      this.coordinates.push(data)
      // await this.centerAndAnimate(item[0], item[1])
    },
    async buscarDirecciones() {
      this.addressList = []
      // const options = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify(data)
      // };
      let url =
        'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
        this.address +
        '.json?proximity=ip&types=place%2Cpostcode%2Caddress&country=ni&types=place,poi,address,neighborhood,district,locality&language=es&access_token=' +
        this.accessToken
      let req = await fetch(url)
      let res = await req.json()
      console.log('direcciones--->', res)
      this.coordinates = []
      for await (let it of res.features) {
        // let coord=it.split(',')

        this.coordenadas = it.center[0] + ', ' + it.center[1]
        // this.row.coordenadas_inicio = event.mapboxEvent.lngLat.lat + ',' + event.mapboxEvent.lngLat.lng

        let data = { coordinates: JSON.parse('[' + this.coordenadas + ']'), cliente_id: 1 }
        this.coordinates.push(data)
        let dataAddress = {
          id: it.id,
          center: it.center,
          name: it.place_name,
        }
        this.addressList.push(dataAddress)
      }
      console.log(this.coordinates)

      // res.features

      // https://api.mapbox.com/geocoding/v5/mapbox.places/buenos%20aires%20rivas.json?proximity=ip&types=place%2Cpostcode%2Caddress&access_token=YOUR_MAPBOX_ACCESS_TOKEN
    },
    async calcularPrecioMultipuntos() {
      console.log('************ iniciando calculos ***************')
      console.log(this.calculosMultipuntos)
      //obtener distancia, tiempo etc.
      this.calculosMultipuntos = []
      this.loading = true
      let contador1 = 0
      let contador2 = 1
      let limite = this.coordenadasMultipunto.length - 1

      //agregar el primer registro, sin precioxdistancia, distanciaviaje,etc

      this.calculosMultipuntos.push({
        id: 0,
        tiempo_viaje: 0,
        distancia_viaje: 0,
        precio_x_distancia: 0,
        total: 0,
        calculos: `--`,
        coordenadas_inicio: '',
        coordenadas_entrega: this.coordenadasMultipunto[contador1].coordenadas,
        direccion: this.coordenadasMultipunto[contador1].nombre,
        telefono: this.coordenadasMultipunto[contador1].telefono,
        descripcion: this.coordenadasMultipunto[contador1].descripcion,
      })

      let contadorit = 1
      for (let ii = 0; ii < limite; ii++) {
        console.log(
          ii + '-',
          this.coordenadasMultipunto[contador1].coordenadas,
          ',',
          this.coordenadasMultipunto[contador2].coordenadas,
        )
        let dataCoord = await this.getDataCoords(
          this.coordenadasMultipunto[contador1].coordenadas,
          this.coordenadasMultipunto[contador2].coordenadas,
        )
        let resp = await dataCoord.json()
        let tiempoViaje = (resp.routes[0].duration.toFixed(0) / 60).toFixed(2)
        let distanciaViaje = resp.routes[0].distance //< 1000 ? resp.routes[0].distance.toFixed(2)  : (resp.routes[0].distance / 1000).toFixed(2)
        console.log('dataco', tiempoViaje)
        console.log('dataco', distanciaViaje)
        let resTarifas = await this.$store.dispatch('get', { path: 'tarifas/getById/1' })
        console.log('resTarifas', resTarifas)
        let precio_x_distancia =
          distanciaViaje < 5 ? resTarifas.precio_x_metro_30_max : resTarifas.precio_x_metro_60_max
        let total =
          Number(distanciaViaje) * precio_x_distancia +
          resTarifas.precio_x_retirar +
          resTarifas.publicidad +
          resTarifas.precio_x_deposito +
          resTarifas.precio_x_entregar
        this.calculosMultipuntos.push({
          id: contadorit,
          tiempo_viaje: tiempoViaje,
          distancia_viaje: distanciaViaje,
          precio_x_distancia: precio_x_distancia,
          total: total,
          calculos: `Distancia aprox.: ${distanciaViaje} m., Tiempo aprox.: ${tiempoViaje} min.`,
          coordenadas_inicio: this.coordenadasMultipunto[contador1].coordenadas,
          coordenadas_entrega: this.coordenadasMultipunto[contador2].coordenadas,
          direccion: this.coordenadasMultipunto[contador2].nombre,
          telefono: this.coordenadasMultipunto[contador2].telefono,
          descripcion: this.coordenadasMultipunto[contador2].descripcion,
        })

        contador1++
        contador2++
        contadorit++
      }
      //pintando puntos en mapa
      this.coordinates = []
      for await (let itm of this.coordenadasMultipunto) {
        let cc1 = itm.coordenadas
        cc1 = cc1.split(',')

        this.coordenadas = cc1[1] + ', ' + cc1[0]
        //this.row.coordenadas_inicio = event.mapboxEvent.lngLat.lat + ',' + event.mapboxEvent.lngLat.lng
        let data = { coordinates: JSON.parse('[' + this.coordenadas + ']'), cliente_id: 1 }
        this.coordinates.push(data)
      }
      /////////
      console.log('for out -->', this.calculosMultipuntos)
      this.row.total = this.calculosMultipuntos
        .reduce((a, b) => {
          return a + b.total
        }, 0)
        .toFixed(2)
      this.loading = false
    },
    removePoint(item) {
      console.log('eliminando', item)
      let itt = [...this.coordenadasMultipunto]
      console.log('cc', itt)
      let result = itt.indexOf(item)
      console.log('eliminando', result)
      if (result > -1) {
        this.coordenadasMultipunto.splice(result, 1)
      }
    },
    clickMapaMultipuntos(event) {
      this.coordinates = []
      this.coordenadas = event.mapboxEvent.lngLat.lng + ', ' + event.mapboxEvent.lngLat.lat
      this.row.coordenadas_inicio = event.mapboxEvent.lngLat.lat + ',' + event.mapboxEvent.lngLat.lng

      let data = { coordinates: JSON.parse('[' + this.coordenadas + ']'), cliente_id: 1 }
      this.coordinates.push(data)
    },
    mostrarToast(titulo, mensaje, tipo) {
      this.snackbar = {
        color: tipo,
        icon: tipo,
        mode: 'multi-line',
        title: titulo,
        text: mensaje, //'Debes seleccionar coordenada / dirección!',
        timeout: '2500',
      }
      this.isSnackbarVisible = true
    },
    async agregarPunto() {
      try {
        if (this.row.tipo == 0 || this.row.tipo == null) {
          this.mostrarToast('Error!', 'Debes seleccionar tipo de orden!.', 'error')
          return
        }
        if (this.coordenadasMultipunto.length == 2 && this.row.tipo == 1) {
          this.mostrarToast('Error!', 'Si quieres agregar más puntos selecciona multipuntos!', 'error')
          return
        }
        if (this.row.cliente_id == 0 || this.row.cliente_id == null) {
          this.mostrarToast('Error!', 'Debes seleccionar Cliente', 'error')
          return
        }
        if (this.row.coordenadas_inicio == '' || this.row.coordenadas_inicio == null) {
          this.mostrarToast('Error!', 'Debes seleccionar coordenada / dirección!', 'error')
          return
        }
        if (this.rowAddress.detalle == '' || this.rowAddress.detalle == null) {
          this.mostrarToast('Error!', 'Falta detalle!', 'error')
          return
        }
        if (this.rowAddress.direccionExacta == '' || this.rowAddress.direccionExacta == null) {
          this.mostrarToast('Error!', 'Falta dirección!', 'error')
          return
        }
        if (this.rowAddress.telefono == '' || this.rowAddress.telefono == null) {
          this.mostrarToast('Error!', 'Falta teléfono!', 'error')
          return
        }

        let arrTmp = [...this.coordenadasMultipunto]
        console.log('ejecutar', arrTmp)
        let contador = await (this.coordenadasMultipunto == null || this.coordenadasMultipunto == []
          ? 1
          : this.coordenadasMultipunto.length++)
        // let itt1 = {
        //   id: contador,
        //   coordenadas: this.row.coordenadas_inicio
        // }

        arrTmp.push({
          id: contador,
          coordenadas: this.row.coordenadas_inicio,
          descripcion: this.rowAddress.detalle,
          telefono: this.rowAddress.telefono,
          nombre: this.rowAddress.direccionExacta,
        })
        console.log('ejecutar2', arrTmp)
        let nu = arrTmp.indexOf(x => x == null)
        console.log('nu', nu)
        //this.coordenadasMultipunto.splice(nu,1)
        let cconta = 0
        for await (let iit of arrTmp) {
          console.log('ii ', iit)
          if (iit == undefined) {
            arrTmp.splice(cconta, 1)
          }
          cconta++
        }
        this.coordenadasMultipunto = arrTmp
      } catch (error) {
        console.log('error', error)
      }
    },
    async saveData2() {
      try {
        // if (this.coordenadasMultipunto.length < 2) {
        //   this.mostrarToast('Error!', 'Debes agregar más puntos!', 'error')
        //   return
        // }
        // if (this.row.metodo_pago == 0 || this.row.metodo_pago == null) {
        //   this.mostrarToast('Error!', 'Debes selecionar método de pago.', 'error')
        //   return
        // }
        // if (this.row.total == 0 || this.row.total == null) {
        //   this.mostrarToast('Error!', 'Debes calcular total.', 'error')
        //   return
        // }
        let row = Object.assign({}, this.row)
        let res
        // if (row.id) {
        //   delete row.id
        //   delete row.categoria
        // res = await this.$store.dispatch('put', { path: 'multipuntos/modify/' + this.row.id, data: row })
        // } else {
        delete row.coordenadas_inicio
        let data = {
          datos: row,
          puntos: this.calculosMultipuntos,
        }
        res = await this.$store.dispatch('post', { path: 'multipuntos/new/', data: data })
        // }
        //this.getData()
        this.snackbar = {
          color: 'success',
          icon: 'success',
          mode: 'multi-line',
          title: 'Muy bien!',
          text: 'Registro procesado correctamente!.',
          timeout: '2500',
        }
        this.isDialogVisible = false
        this.isSnackbarVisible = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    mapClicked(event) {
      //alert('click')
      console.log(event)
      //console.log(event.mapboxEvent.lngLat.lng+ ','+event.mapboxEvent.lngLat.lat)
      this.coordinates = []
      this.coordenadas = event.mapboxEvent.lngLat.lng + ', ' + event.mapboxEvent.lngLat.lat
      //this.coordenadas_ubicacion = this.coordenadas
      if (this.tipocoord == 1)
        this.row.coordenadas_inicio = event.mapboxEvent.lngLat.lat + ',' + event.mapboxEvent.lngLat.lng

      if (this.tipocoord == 2)
        this.row.coordenadas_entrega = event.mapboxEvent.lngLat.lat + ',' + event.mapboxEvent.lngLat.lng

      let data = { coordinates: JSON.parse('[' + this.coordenadas + ']'), cliente_id: 1 }
      //vector["coordinates"]=item.ubicacion
      this.coordinates.push(data)
    },
    showMap(type) {
      this.coordinates = []
      this.showMapa = !this.showMapa
      this.tipocoord = type
    },
    async getDataCoords(cc1, cc2) {
      let accessToken = 'pk.eyJ1IjoieWFxdWkiLCJhIjoiY2wzcjF3bXljMGh4czNibXZkY2tseWdnMiJ9.g7nt6H62ljJjIG72Kws1dA'
      let co1 = cc1.split(',')
      let co2 = cc2.split(',')
      console.log(co1)
      console.log(co2)
      let lng1 = co1[1]
      let lat1 = co1[0]
      let lng2 = co2[1]
      let lat2 = co2[0]

      let endPoint = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${lng1}%2C${lat1}%3B${lng2}%2C${lat2}?alternatives=true&geometries=geojson&language=en&overview=simplified&steps=true&access_token=pk.eyJ1IjoieWFxdWkiLCJhIjoiY2wzcjF3bXljMGh4czNibXZkY2tseWdnMiJ9.g7nt6H62ljJjIG72Kws1dA`

      //consumir servicio
      //console.log('endPoint ////////////////////////',endPoint)
      const queryCal = await fetch(endPoint, { method: 'GET' })
      return queryCal
    },
    async calcularPrecio() {
      //obtener distancia, tiempo etc.
      this.loading = true
      let dataCoord = await this.getDataCoords(this.row.coordenadas_inicio, this.row.coordenadas_entrega)
      let resp = await dataCoord.json()
      let tiempoViaje = (resp.routes[0].duration.toFixed(0) / 60).toFixed(2)
      this.distanciaViaje = resp.routes[0].distance //< 1000 ? resp.routes[0].distance.toFixed(2)  : (resp.routes[0].distance / 1000).toFixed(2)

      console.log('dataco', tiempoViaje)
      console.log('dataco', this.distanciaViaje)

      let resTarifas = await this.$store.dispatch('get', { path: 'tarifas/getById/1' })
      console.log('resTarifas', resTarifas)
      this.loading = false
      let precio_x_distancia =
        this.distanciaViaje < 5 ? resTarifas.precio_x_metro_30_max : resTarifas.precio_x_metro_60_max
      this.row.total =
        Number(this.distanciaViaje) * precio_x_distancia +
        resTarifas.precio_x_retirar +
        resTarifas.publicidad +
        resTarifas.precio_x_deposito +
        resTarifas.precio_x_entregar
      this.calculos = `Distancia aprox.: ${this.distanciaViaje} m., Tiempo aprox.: ${tiempoViaje} min.`
      //obtener tarifas para calcular precio
    },
    async saveData() {
      try {
        console.log('data to save ', this.row)
        let dataToSave = {
          estado_dinero_recogido: this.row.estado_dinero_recogido,
          estado_pago_envio: this.row.estado_pago_envio,
        }

        //pendiente la validación del form
        // this.formHasErrors = false
        //let row = Object.assign({}, this.row)
        // console.log('row', row)

        // Object.keys(row).forEach(f => {
        //   if (f == 'nombre') {
        //     if (!row[f]) this.formHasErrors = true

        //     this.$refs[f].validate(true)
        //   }
        // })
        ///
        let res
        //es update
        res = await this.$store.dispatch('put', { path: this.table + '/modify/' + this.row.id, data: dataToSave })

        this.getData()
        this.snackbar = {
          color: 'success',
          icon: 'success',
          mode: 'multi-line',
          title: 'Muy bien!',
          text: 'Registro procesado correctamente!.',
          timeout: '2500',
        }

        this.isDialogVisible = false
        this.isSnackbarVisible = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    async showModal(type, item) {
      if (type == 1) {
        this.modalTitle = 'Nueva orden'
        this.row = {
          coordenadas_inicio: '',
          coordenadas_entrega: '',
        }
        //this.calculos= ''
        this.calculos = ''
        this.isDialogVisible = true
      }
      if (type == 2) {
        console.log('2', item)
        this.row = item
        this.modalTitle = 'Editar orden'
        this.calculos = ''
        this.isDialogVisible = true
        this.urlSeguimiento = await this.seguimientoOrden(item.idd)
      }
      if (type == 3) {
        this.row = {
          coordenadas_inicio: '',
          coordenadas_entrega: '',
        }
        // this.modalTitle = 'Nueva orden'
        this.calculos = ''
        this.isDialogVisible = false
        // this.isDialogVisible2 = true
        this.$router.push('/apps/neworder')
      }
    },
    async getData() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', {
          path:
            this.table +
            '/getAllByDriver/' +
            this.dataOrders.driverId +
            '/' +
            this.dataOrders.desde +
            '/' +
            this.dataOrders.hasta,
        })
        console.log('orders ', res)
        this.loading = false
        if (res.length > 0) {
          this.items = res
          this.items2 = res
          this.totalItems = this.items.length
        }
      } catch (error) {
        this.items = []
        this.items2 = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getCustomers() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'clientes/getAll/' })
        this.loading = false
        if (res.length > 0) {
          this.customersList = res
        }
      } catch (error) {
        this.customersList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getCommerce() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'comercios/getAll/' })
        this.loading = false
        if (res.length > 0) {
          this.commerceList = res
        }
      } catch (error) {
        this.commerceList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getCategories() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'categorias/getAll/' })
        this.loading = false
        if (res.length > 0) {
          this.categoriesList = res
        }
      } catch (error) {
        this.categoriesList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getSubcategoriesByCategory(category_id) {
      try {
        console.log('sel', category_id)
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'subcategorias/getAllByCategory/' + category_id })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.subcategoriesList = res
        } else {
          this.subcategoriesList = []
          this.row.subcategoria_id = 0
        }
      } catch (error) {
        this.subcategoriesList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async deleteData(item) {
      try {
        let txt
        if (confirm('¿Eliminar Registro?')) {
          this.loading = true
          let res = await this.$store.dispatch('delete', { path: this.table + '/' + item.id })
          await this.getData()
          this.loading = false
          txt = 'You pressed OK!'
          this.snackbar = {
            color: 'success',
            icon: 'success',
            mode: 'multi-line',
            title: 'Muy bien!',
            text: 'Registro elimiado correctamente!.',
            timeout: '2500',
          }
          this.isSnackbarVisible = true
        } else {
          //txt = 'You pressed Cancel!'
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
}

.btn-green {
  color: rgb(255, 255, 255) !important;
  background-color: #20b11b !important;
  border-color: #20b11b !important;
  // height: 30px;
}

.v-icon__svg {
  // height: 15px;
  // vertical-align: middle;
  // margin-top: 0px;
  // width: 10px;
}

.btn-blue2 {
  color: #fff !important;
  background-color: #59a8fc !important;
  border-color: #469efc !important;
}

.btn-blue {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.btn-red {
  color: #fff !important;
  background-color: #ff0037 !important;
  border-color: #ff0037 !important;
}

.btn-orange {
  color: #fff !important;
  background-color: #e88813 !important;
  border-color: #e88813 !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.155rem 0.155rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.custom-search {
  position: relative;
  width: 400px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.custom-search-input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 100px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
}

.custom-search-botton {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  background: #f2c309;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 10px;
  z-index: 2;
}

.bg_alert {
  background-color: rgb(243, 92, 92) !important;
}

.bg_alert :hover {
  background-color: rgb(243, 92, 92);
}

span {
  font-size: 12px !important;
}

.bg-red {
  color: black !important;
  background-color: rgb(255, 171, 171);
}

.bg-red:hover {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(255, 171, 171) !important;
}

.bg-green {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(177, 255, 199);
}

.bg-green:hover {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(177, 255, 199) !important;
}

.bg-blue {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(154, 156, 255);
}

.bg-blue:hover {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(154, 156, 255) !important;
}

.bg-lightblue {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(155, 215, 231);
}

.bg-lightblue:hover {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(155, 215, 231) !important;
}

.bg-yellow {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(255, 225, 0);
}

.bg-yellow:hover {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(234, 209, 21) !important;
}

.bg-white {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(255, 255, 255);
}

// .v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
//   background: inherit !important;
// }
.v-data-table__wrapper>table>tbody>tr:hover {
  // background: rgba($color: #92929261, $alpha: 0.5) !important;
}
</style>
